import { Question } from './types';

export const getFrequentlyAskedQuestions = (): Question[] => [
  {
    label: 'What is Okapi Digital Literacy™?',
    children: (
      <>
        <strong>Okapi Digital Literacy™</strong> is a digital book platform that enables teachers to view and share Okapi's
        resources with their students. In addition, teachers can view and download lesson plans and other essential instructional
        resources from all of Okapi’s flagship programs.
      </>
    )
  },
  {
    label: 'How many student titles are available?',
    children: (
      <>
        The <strong>Okapi Digital Literacy™</strong> book platform provides access to more than 1200 student texts (Decodable
        Readers through Level V) with teaching support for every title.
      </>
    )
  },
  {
    label: 'What programs are available?',
    children: (
      <>
        Okapi Digital Literacy™ Digital delivers content and instruction from all of Okapi’s flagship programs:
        <ul>
          <li>
            <i>
              <strong>Flying Start to Literacy: PHONICS™</strong>
            </i>
          </li>
          <li>
            <i>
              <strong>Flying Start to Literacy™</strong>
            </i>
          </li>
          <li>
            <i>
              <strong>Despegando hacia la lectura™</strong>
            </i>
          </li>
          <li>
            <i>
              <strong>WorldWise: Content-Based Learning™</strong>
            </i>
          </li>
          <li>
            <i>
              <strong>ExploraMundos™</strong>
            </i>
          </li>
          <li>
            <i>
              <strong>Biliteracy para todos™</strong>
            </i>
          </li>
        </ul>
      </>
    )
  },
  {
    label: 'How do I locate the title(s) I want?',
    children: (
      <>
        Upon arriving at the platform, you are presented with a left-hand menu that includes a variety of filters to help you
        narrow your selection of books. Filter by:
        <ul>
          <li>Series</li>
          <li>Reading Level</li>
          <li>Stage</li>
          <li>Curriculum</li>
          <li>Language</li>
        </ul>
        Why do some books appear in shadow (gray in color) while others are in full color? When first logging onto the platform,
        you may encounter grayed-out books. Some titles will be inaccessible if they are not included within your school’s
        license. To arrive at the titles to which you have access, simply click on any series covered by your school’s license.
      </>
    )
  },
  {
    label: 'How do I find a specific range of titles using the filter menu?',
    children: (
      <>
        For example, if your class is working their way through{' '}
        <strong>
          <i>Flying Start’s</i> Early Emergent Stage
        </strong>
        , you would click the <i>Flying Start to Literacy</i> button in <strong>Series</strong> and then select the{' '}
        <i>Early Emergent Stage</i> in the <strong>Stage</strong> dropdown menu.
      </>
    )
  },
  {
    label: 'How do I refresh the filter menu?',
    children: (
      <>
        Filters can be cleared by re-clicking any of the selections or by choosing <strong>Clear All</strong> at the top of the
        menu window.
      </>
    )
  },
  {
    label: 'How do I flip through the pages of a title?',
    children: (
      <>
        <p>
          First, select a title by clicking on its thumbnail picture. The book will open on your screen. To the right of the book
          is an arrow that turns the pages forward. Books progress forward in double-page spreads, which – at the early levels –
          keep words and pictures together within the same student view. On the left is an arrow which turns the pages backward.
        </p>
        <p>
          Under that is another arrow icon that will return the reader to the front of the book. The corresponding arrow icon on
          the right will move the reader to the end of the book.
        </p>
      </>
    )
  },
  {
    label: 'How do I share a book with students?',
    children: (
      <>
        <p>
          Once a title has been selected, a horizontal menu will appear in the upper right corner of the screen. Simply click on
          the <strong>Share Book</strong> feature to copy the link and then share that link with students in an email.
        </p>
        <p>
          When your student clicks on that link, the book appears in its own window without the menu of teacher options, allowing
          students to focus only on the book you sent them.
        </p>
      </>
    )
  },
  {
    label: 'Can I toggle between paired books?',
    children: (
      <>
        Because{' '}
        <strong>
          <i>Flying Start</i>
        </strong>{' '}
        and{' '}
        <strong>
          <i>Despegando</i>
        </strong>{' '}
        are paired-text programs, you will appreciate the ease with which the platform allows you to toggle between each of the
        books in the pair. From that menu in the upper right of your screen, simply click on <strong>Paired Text</strong> and the
        other half of the pair is pulled into view.
      </>
    )
  },
  {
    label: 'How do I go back to the original title in the pair?',
    children: (
      <>
        To go back to the original title in the pair, simply click again on <strong>Paired Text</strong>.
      </>
    )
  },
  {
    label: 'Where can I find the Vocabulary Starters?',
    children: (
      <>
        With a{' '}
        <strong>
          <i>Flying Start</i>
        </strong>{' '}
        or{' '}
        <strong>
          <i>Despegando</i> Level A or B
        </strong>{' '}
        title selected, the horizontal menu in the upper right corner will provide you with access to that pair's{' '}
        <strong>Vocabulary Starter</strong>.
      </>
    )
  },
  {
    label: 'How do I download lesson plans?',
    children: (
      <>
        <p>
          Lesson plans can be downloaded by subscribers to the platform. (On the Preview Site, the downloading option is
          disabled.) Once a book has been selected, connect to the lesson plan by clicking on <strong>Lesson Plan</strong> from
          the menu in the upper right of your screen. In the dropdown menu, choose between our national lesson plan or the Texas
          Essential Knowledge and Skills (TEKS) lesson plan.
        </p>
        <p>Once you have chosen between the two, a Download option will appear in the upper right menu.</p>
      </>
    )
  },
  {
    label: 'How do I transition to a different language?',
    children: (
      <>
        With a title selected, the upper-right menu concludes with a toggle that will take you directly to a book's alternate
        language title. To return to the original language edition, simply make that choice from the menu again.
      </>
    )
  },
  {
    label: 'Where can I locate Perspectives texts?',
    children: (
      <>
        <p>
          Flying Start's <i>Perspectives</i> are available for Levels N through V. Similarly, the same volumes are included in
          Spanish in <i>Despegando</i>, as <i>Perspectivas</i>. Paired books with <i>Perspectives</i> follow a consistent order on
          the book platform: the Levels N-V paired texts are always followed immediately by their <i>Perspectives</i> volume.
        </p>
        <p>
          When one of the books from the pair has been selected, the menu in the upper right offers the <i>Perspectives</i> volume
          as a link. If you choose a <i>Perspectives</i> title, this menu option changes for an easy return to the paired texts.
        </p>
      </>
    )
  },
  {
    label: 'Can I increase the size of a book on my screen?',
    children: (
      <>
        With a book selected, click on the icon of four arrows in the lower right to increase the size of the display and make it
        easier to see and read text. Reduce the size by clicking on the icon again.
      </>
    )
  },
  {
    label: 'Can I search a book for vocabulary?',
    children: (
      <>
        Clicking on the magnifying glass icon will split the screen between the book spread and a new inset. The inset allows you
        to enter a search term, which will return the number of occurrences the word appears in the book. You now have the choice
        of clicking out of this inset or selecting one of the options, which takes you to the selected page with the highlighted
        term.
      </>
    )
  },
  {
    label: 'Is there a way to easily navigate to a specific page?',
    children: (
      <>
        Clicking the icon made up of <strong>four squares</strong> will open an inset window displaying thumbnails of all the
        book's pages. Use the scrolling tool to move through the pages and select the one you want to show.
      </>
    )
  },
  {
    label: 'Can I make annotations to the text?',
    children: (
      <>
        <p>
          Clicking the <strong>pencil icon</strong> at the bottom right of the screen will open the{' '}
          <strong>Annotations Menu</strong> and a variety of annotation features. (This menu opens onto the book itself, but it is
          easy to move it to any location on your screen. Simply click and hold the left-most portion of the menu and drag it to
          the location of your choice.)
        </p>
        <p>Okapi Digital Literacy offers countless ways of adapting texts for vibrant and interactive instruction:</p>
        <ul>
          <li>Add sticky notes in a variety of colors (date- and time-stamped for you automatically)</li>
          <li>Draw freestyle with your curser</li>
          <li>Enclose portions of texts in a variety of shapes</li>
          <li>Highlight and save to your clipboard any relevant passage</li>
          <li>Remove alterations easily</li>
          <li>Save your annotated version</li>
        </ul>
      </>
    )
  },
  {
    label: 'Can I locate a specific title using the search bar?',
    children: (
      <>
        The search bar at the top of your screen allows you to search the platform by title or ISBN. Entering just a portion of a
        title creates a dropdown menu of all titles containing that word. Alternatively, use the search bar to locate books by
        their ISBN.
      </>
    )
  },
  {
    label: "Where can I find my program's the instructional tools?",
    children: (
      <>
        Okapi Digital Literacy™ offers valuable instructional tools for use with Okapi’s flagship programs. To access these
        ancillaries, click on the Resources tab at the top center of the platform's main page. Files are arranged in sequence of
        instruction and can be downloaded for printing. Choose from among these indispensable resources:
        <ul>
          <li>
            <strong>Flying Start to Literacy: PHONICS™</strong>
          </li>
          <li>
            <strong>Biliteracy para todos™</strong> <i>Investigations</i> and <strong>ExploraMundos™</strong>{' '}
            <i>Investigaciones</i>
          </li>
          <li>
            <strong>WorldWise: Content-based Learning™</strong>{' '}
          </li>
          <li>
            <strong>Texas Test Prep </strong>
          </li>
        </ul>
      </>
    )
  },
  {
    label: 'How do I get unrestricted access Okapi Digital Literacy™? ',
    children: (
      <>
        Gain full access to <strong>Okapi Digital Literacy™</strong> with a FREE trial! This special license arrangement grants
        you access to the entire resource with all its features and functionality. Contact your local Okapi representative for
        more details.
      </>
    )
  },
  {
    label: "Don't see what you're looking for?",
    children: <>Contact us directly and we'll be happy to help answer any questions you might have.</>
  }
];
