import * as React from "react";
const SvgLogo = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 1906 716", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeMiterlimit: 10
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-1606.09,-1249.83)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(4.16667,0,0,4.16667,0,0)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(124,0,0,124,434.27,391.72)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.034,-0.278C0.034,-0.142 0.131,0.017 0.333,0.017C0.535,0.017 0.632,-0.142 0.632,-0.278C0.632,-0.414 0.535,-0.573 0.333,-0.573C0.131,-0.573 0.034,-0.414 0.034,-0.279L0.034,-0.278ZM0.2,-0.278C0.2,-0.359 0.261,-0.418 0.333,-0.418C0.405,-0.418 0.466,-0.359 0.466,-0.278C0.466,-0.197 0.405,-0.138 0.333,-0.138C0.261,-0.138 0.2,-0.197 0.2,-0.277L0.2,-0.278Z", style: {
  fill: "rgb(45,105,179)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(124,0,0,124,516.854,391.72)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.066,-0L0.232,-0L0.232,-0.267L0.391,-0L0.579,-0L0.376,-0.311L0.585,-0.555L0.388,-0.555L0.232,-0.354L0.232,-0.74L0.066,-0.74L0.066,-0Z", style: {
  fill: "rgb(45,105,179)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(124,0,0,124,585.178,391.72)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.643,-0.555L0.477,-0.555L0.477,-0.496C0.455,-0.53 0.404,-0.574 0.313,-0.574C0.165,-0.574 0.034,-0.46 0.034,-0.278C0.034,-0.119 0.137,0.019 0.313,0.019C0.378,0.019 0.447,-0.006 0.477,-0.06L0.477,-0L0.643,-0L0.643,-0.555ZM0.2,-0.276C0.2,-0.339 0.242,-0.419 0.337,-0.419C0.407,-0.419 0.445,-0.38 0.463,-0.342C0.474,-0.319 0.479,-0.294 0.477,-0.269C0.475,-0.244 0.468,-0.22 0.454,-0.198C0.433,-0.164 0.397,-0.136 0.338,-0.136C0.238,-0.136 0.2,-0.224 0.2,-0.275L0.2,-0.276Z", style: {
  fill: "rgb(45,105,179)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(124,0,0,124,672.474,391.72)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.06,0.185L0.226,0.185L0.226,-0.05C0.246,-0.029 0.293,0.019 0.393,0.019C0.551,0.019 0.663,-0.112 0.663,-0.272C0.663,-0.401 0.589,-0.574 0.392,-0.574C0.307,-0.574 0.252,-0.536 0.216,-0.494L0.216,-0.555L0.06,-0.555L0.06,0.185ZM0.497,-0.277C0.497,-0.207 0.445,-0.136 0.358,-0.136C0.319,-0.136 0.284,-0.152 0.259,-0.177C0.234,-0.202 0.219,-0.237 0.219,-0.276C0.219,-0.316 0.234,-0.352 0.258,-0.377C0.283,-0.403 0.318,-0.419 0.358,-0.419C0.449,-0.419 0.497,-0.343 0.497,-0.278L0.497,-0.277Z", style: {
  fill: "rgb(45,105,179)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(124,0,0,124,758.902,391.72)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.06,-0.614L0.226,-0.614L0.226,-0.74L0.06,-0.74L0.06,-0.614ZM0.06,-0L0.226,-0L0.226,-0.555L0.06,-0.555L0.06,-0Z", style: {
  fill: "rgb(45,105,179)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,437.902,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.619,-0.74L0.515,-0.74L0.515,-0.455C0.493,-0.498 0.425,-0.56 0.314,-0.56C0.159,-0.56 0.037,-0.442 0.037,-0.273C0.037,-0.116 0.145,0.016 0.316,0.016C0.428,0.016 0.489,-0.039 0.519,-0.095L0.519,-0L0.619,-0L0.619,-0.74ZM0.141,-0.273C0.141,-0.375 0.217,-0.463 0.332,-0.463C0.385,-0.463 0.432,-0.443 0.466,-0.409C0.5,-0.376 0.521,-0.329 0.521,-0.275C0.523,-0.221 0.503,-0.173 0.468,-0.138C0.434,-0.103 0.385,-0.081 0.332,-0.081C0.212,-0.081 0.142,-0.172 0.141,-0.272L0.141,-0.273Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,472.49,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.068,-0.63L0.172,-0.63L0.172,-0.74L0.068,-0.74L0.068,-0.63ZM0.068,-0L0.172,-0L0.172,-0.544L0.068,-0.544L0.068,-0Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,484.538,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.618,-0.544L0.514,-0.544L0.514,-0.452C0.484,-0.512 0.409,-0.56 0.318,-0.56C0.16,-0.56 0.037,-0.44 0.037,-0.27C0.037,-0.108 0.155,0.016 0.318,0.016C0.416,0.016 0.482,-0.035 0.514,-0.096L0.514,-0.04C0.514,0.082 0.42,0.149 0.329,0.149C0.305,0.149 0.268,0.142 0.235,0.12C0.208,0.103 0.187,0.081 0.175,0.057L0.055,0.057C0.081,0.148 0.171,0.238 0.325,0.238C0.419,0.238 0.504,0.197 0.554,0.139C0.594,0.093 0.618,0.032 0.618,-0.063L0.618,-0.544ZM0.141,-0.273C0.141,-0.385 0.226,-0.462 0.329,-0.462C0.395,-0.462 0.442,-0.436 0.472,-0.398C0.502,-0.361 0.516,-0.312 0.516,-0.269C0.515,-0.216 0.495,-0.169 0.463,-0.136C0.43,-0.102 0.384,-0.081 0.329,-0.081C0.214,-0.081 0.141,-0.17 0.141,-0.272L0.141,-0.273Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,518.925,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.068,-0.63L0.172,-0.63L0.172,-0.74L0.068,-0.74L0.068,-0.63ZM0.068,-0L0.172,-0L0.172,-0.544L0.068,-0.544L0.068,-0Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,530.973,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.107,-0L0.211,-0L0.211,-0.463L0.316,-0.463L0.316,-0.544L0.211,-0.544L0.211,-0.74L0.107,-0.74L0.107,-0.544L0.009,-0.544L0.009,-0.463L0.107,-0.463L0.107,-0Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,545.983,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.622,-0.544L0.518,-0.544L0.518,-0.448C0.493,-0.5 0.424,-0.56 0.316,-0.56C0.158,-0.56 0.037,-0.438 0.037,-0.272C0.037,-0.12 0.141,0.016 0.316,0.016C0.402,0.016 0.48,-0.022 0.518,-0.095L0.518,-0L0.622,-0L0.622,-0.544ZM0.141,-0.271C0.141,-0.373 0.215,-0.463 0.329,-0.463C0.416,-0.463 0.477,-0.414 0.503,-0.351C0.515,-0.326 0.52,-0.298 0.519,-0.27C0.518,-0.242 0.512,-0.214 0.5,-0.189C0.473,-0.127 0.414,-0.081 0.331,-0.081C0.199,-0.081 0.142,-0.189 0.141,-0.27L0.141,-0.271Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,580.671,459.73)" }, /* @__PURE__ */ React.createElement("rect", { x: 0.068, y: -0.74, width: 0.104, height: 0.74, style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,607.528,459.73)" }, /* @__PURE__ */ React.createElement("rect", { x: 0.068, y: -0.74, width: 0.104, height: 0.74, style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,619.576,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.068,-0.63L0.172,-0.63L0.172,-0.74L0.068,-0.74L0.068,-0.63ZM0.068,-0L0.172,-0L0.172,-0.544L0.068,-0.544L0.068,-0Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,631.624,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.107,-0L0.211,-0L0.211,-0.463L0.316,-0.463L0.316,-0.544L0.211,-0.544L0.211,-0.74L0.107,-0.74L0.107,-0.544L0.009,-0.544L0.009,-0.463L0.107,-0.463L0.107,-0Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,646.634,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.6,-0.239C0.609,-0.33 0.582,-0.415 0.518,-0.48C0.466,-0.531 0.395,-0.56 0.317,-0.56C0.152,-0.56 0.03,-0.427 0.03,-0.271C0.03,-0.127 0.14,0.016 0.318,0.016C0.373,0.016 0.427,0.001 0.473,-0.03C0.519,-0.06 0.557,-0.105 0.583,-0.165L0.475,-0.165C0.445,-0.108 0.391,-0.073 0.318,-0.073C0.236,-0.073 0.14,-0.129 0.135,-0.239L0.6,-0.239ZM0.137,-0.32C0.142,-0.394 0.211,-0.471 0.317,-0.471C0.42,-0.473 0.492,-0.392 0.497,-0.32L0.137,-0.32Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,678.461,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.068,-0L0.172,-0L0.172,-0.294C0.172,-0.402 0.223,-0.456 0.321,-0.456L0.328,-0.456L0.328,-0.56C0.254,-0.559 0.197,-0.528 0.166,-0.464L0.166,-0.544L0.068,-0.544L0.068,-0Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,693.671,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.622,-0.544L0.518,-0.544L0.518,-0.448C0.493,-0.5 0.424,-0.56 0.316,-0.56C0.158,-0.56 0.037,-0.438 0.037,-0.272C0.037,-0.12 0.141,0.016 0.316,0.016C0.402,0.016 0.48,-0.022 0.518,-0.095L0.518,-0L0.622,-0L0.622,-0.544ZM0.141,-0.271C0.141,-0.373 0.215,-0.463 0.329,-0.463C0.416,-0.463 0.477,-0.414 0.503,-0.351C0.515,-0.326 0.52,-0.298 0.519,-0.27C0.518,-0.242 0.512,-0.214 0.5,-0.189C0.473,-0.127 0.414,-0.081 0.331,-0.081C0.199,-0.081 0.142,-0.189 0.141,-0.27L0.141,-0.271Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,728.36,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.497,-0.191C0.475,-0.137 0.418,-0.082 0.327,-0.081C0.223,-0.081 0.141,-0.16 0.141,-0.272C0.141,-0.327 0.162,-0.378 0.194,-0.41C0.232,-0.446 0.272,-0.464 0.327,-0.463C0.401,-0.463 0.469,-0.422 0.497,-0.349L0.604,-0.349C0.57,-0.48 0.453,-0.56 0.323,-0.56C0.16,-0.56 0.037,-0.433 0.037,-0.272C0.037,-0.121 0.15,0.016 0.328,0.016C0.464,0.016 0.571,-0.073 0.604,-0.191L0.497,-0.191Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(50.2,0,0,50.2,759.885,459.73)" }, /* @__PURE__ */ React.createElement("path", { d: "M0.123,0.198L0.234,0.198L0.531,-0.544L0.419,-0.544L0.267,-0.146L0.117,-0.544L0.005,-0.544L0.213,-0.016L0.123,0.198Z", style: {
  fill: "rgb(35,31,32)",
  fillRule: "nonzero"
} }))), /* @__PURE__ */ React.createElement("g", { transform: "matrix(4.16667,0,0,4.16667,0,0)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,405.56,413.975)" }, /* @__PURE__ */ React.createElement("path", { d: "M0,0L417.07,0", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "rgb(45,105,179)",
  strokeWidth: 4
} })))));
export default SvgLogo;
