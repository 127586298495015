import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

import DropdownMenu from '@okapi/components/DropdownMenu';
import { User, UserStatus } from '@okapi/graphql/types.generated';

interface ActionCallbacks {
  currentUserId: string;
  onEdit: (id: string) => void;
  onActivate: (id: string) => void;
  onDeactivate: (id: string) => void;
  onResendInvitationEmail: (id: string) => void;
}

export const getColumns = ({
  currentUserId,
  onEdit,
  onActivate,
  onDeactivate,
  onResendInvitationEmail
}: ActionCallbacks): ColumnsType => [
  {
    title: 'First name',
    dataIndex: 'firstName'
  },
  {
    title: 'Last name',
    dataIndex: 'lastName'
  },
  {
    title: 'Email',
    dataIndex: 'email'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: UserStatus) => {
      const statusMapping: Record<UserStatus, { color: string; label: string }> = {
        [UserStatus.ACTIVE]: {
          color: 'green',
          label: 'active'
        },
        [UserStatus.INACTIVE]: {
          color: 'volcano',
          label: 'inactive'
        },
        [UserStatus.CREATED]: {
          color: 'gold',
          label: 'invitation sent'
        }
      };

      return <Tag color={statusMapping[status].color}>{statusMapping[status].label}</Tag>;
    }
  },
  {
    title: 'Actions',
    align: 'right',
    key: 'actions',
    fixed: 'right',
    width: 280,
    render: ({ id, status }) => {
      const currentUser = currentUserId === id;

      const items = [
        { label: 'Edit', key: 'edit', onClick: () => onEdit(id) },
        ...(!currentUser && status === UserStatus.INACTIVE
          ? [{ label: 'Activate', key: 'activate', onClick: () => onActivate(id) }]
          : []),
        ...(!currentUser && status !== UserStatus.INACTIVE
          ? [{ label: 'Deactivate', key: 'deactivate', onClick: () => onDeactivate(id) }]
          : []),
        ...(!currentUser && status === UserStatus.CREATED
          ? [{ label: 'Resend email', key: 'resend-email', onClick: () => onResendInvitationEmail(id) }]
          : [])
      ];

      return <DropdownMenu items={items} />;
    }
  }
];
